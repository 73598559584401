export class User {
    id: string;
    name: string;
    email: string;
    photo: string;
    displayTimestamp: string;
    displayLastUpdateTimestamp: string;
    unixLastUpdateTimestamp: number;
    unixTimestamp: number;
    roles: Array<string>;
    dob: string;
    gender?: string;
    bio?: string;
    instagram?: string;
    twitter?: string;
    snapchat?: string;
    eventCount?: number;
    eventTotal?: number;
    cityCount?: number;
    cityTotal?: number;
    ticketCount?: number;
    ticketTotal?: number;
    tableCount?: number;
    tableTotal?: number;
    bottleCount?: number;
    bottleTotal?: number;
    venueCount?: number;
    venueTotal?: number;
    userTotal?: number;
    deviceCount?: number;
    deviceTotal?: number;
    uploadCount?: number;
    uploadTotal?: number;
    postCount?: number;
    postTotal?: number;
    askCount?: number;
    askTotal?: number;
    taskCount?: number;
    taskTotal?: number;
    pingCount?: number;
    pingTotal?: number;
    isPremium: boolean = false;
    premiumUpdatedAt?: any;
    stripeCardToken?: string;
    stripeChargeId?: string;
    stripeCustomerId?: string;
    stripeSubscriptionId?: string;
    subscriptionStatus?: 'active' | 'canceled' | 'past_due' | 'unpaid' | 'incomplete' | null;
    subscriptionPeriodEnd?: number;
    subscriptionPlan?: string;
    userDeleteRunning?: boolean;
    passwordUpdateRunning?: boolean;
    authorizedUserSubscriber?: boolean;
    authorizedUserAdmin?: boolean;
    authorizedUserPromoter?: boolean;
    authorizedUserCustomer?: boolean;
    authorizedUserOwner?: boolean;
    adminPromoterProfilesLink?: string;
    promoterProfilesLink?: string;
    eventBriteConnectRegistrationLink?: string;
    stripeConnectRegistrationLink?: string;
    messageRunning?: boolean;
    messageCount?: number;
    messageTotal?: number;
    promoterProfileCount?: number;
    promoterProfileTotal?: number;
    promoterRequestCount?: number;
    promoterRequestTotal?: number;
    pendingPromoterApproval?: boolean;
    userPromoterId?: string;
  uid: string | number | boolean;

    constructor(data: Partial<User>) {
        Object.assign(this, data);
        this.isPremium = data.isPremium ?? false;
    }
}
