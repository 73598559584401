import { Component, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-ticket-detail-modal',
  templateUrl: './ticket-detail-modal.component.html',
  styleUrls: ['./ticket-detail-modal.component.scss']
})
export class TicketDetailModalComponent implements OnInit {
  @Input() ticket: {
    image?: string;
    eventName: string;
    date: Date;
    city: string;
    state: string;
    country: string;
    amountPurchased: number;
    tags?: string[];
    promoterName: string;
    promoterPhoto: string;
    promoterId: string;
    eventId: string;
    orderId: string;
    pricePaid: number;
    orderDate?: Date;
    ticketType?: string;
  };
  @Input() qrCODE: string;

  maleQRCode: string = '';
  femaleQRCode: string = '';
  viewingMaleQRCode: boolean = false;
  viewingFemaleQRCode: boolean = false;
  elementType = 'url';
  encodeURIComponent = encodeURIComponent;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    console.log('Ticket:', this.ticket);
    if (this.ticket && this.ticket.eventId) {
      this.maleQRCode = `https://imp-events.com/promoter/${this.ticket.promoterId}/event/${this.ticket.eventId}/m`;
      this.femaleQRCode = `https://imp-events.com/promoter/${this.ticket.promoterId}/event/${this.ticket.eventId}/f`;
      console.log('Male QR:', this.maleQRCode);
      console.log('Female QR:', this.femaleQRCode);
    }
  }

  close() {
    this.modalController.dismiss();
  }

  toggleMaleQRCode() {
    this.viewingMaleQRCode = !this.viewingMaleQRCode;
    this.viewingFemaleQRCode = false;
  }

  toggleFemaleQRCode() {
    this.viewingFemaleQRCode = !this.viewingFemaleQRCode;
    this.viewingMaleQRCode = false;
  }
}
