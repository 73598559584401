import { User } from './tdct-user.model';
export const JackUser: User = {
    id: '1',
    uid: '1',
    name: 'Jack',
        dob: '',
        gender: '',
    email: 'test-jack@tdct.io',
    photo: 'assets/img/default-profile.png',
    bio: 'I\'m Blocked',
    instagram: 'instagram.com/jack',
    twitter: 'twitter.com/jack',
    snapchat: 'snapchat.com/add/jack',
    displayTimestamp: 'a few hours ago',
    displayLastUpdateTimestamp: 'a few hours ago',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    roles: ['blocked'],
    isPremium: false
};
export const JaneUser: User = {
    id: '2',
    uid: '2',
    name: 'Jane',
    email: 'test-jane@tdct.io',
    photo: 'assets/img/default-profile.png',
    bio: 'Hi I\'m Jane. I\'m a promoter looking to throw events',
    twitter: 'twitter.com/jane',
    instagram: 'instagram.com/jane',
    snapchat: 'snapchat.com/add/jane',
    displayTimestamp: 'a few hours ago',
    displayLastUpdateTimestamp: 'a few hours ago',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    roles: ['subscriber', 'promoter'],
    eventCount: 0,
        dob: '',
        gender: '',
    isPremium: false
};
export const JimUser: User = {
    id: '3',
    uid: '3',
    name: 'Jim',
    email: 'test-jim@tdct.io',
        dob: '',
        gender: '',
    photo: 'assets/img/default-profile.png',
    displayTimestamp: 'a few hours ago',
    displayLastUpdateTimestamp: 'a few hours ago',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    roles: ['subscriber', 'customer'],
    eventCount: 2,
    isPremium: false
};
export const JillUser: User = {
    id: '4',
    uid: '4',
    name: 'Jill',
        dob: '',
        gender: '',
    email: 'test-jill@tdct.io',
    photo: 'assets/img/default-profile.png',
    displayTimestamp: 'a few hours ago',
    displayLastUpdateTimestamp: 'a few hours ago',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    roles: ['subscriber', 'promoter', 'customer'],
    eventCount: 1,
    isPremium: false
};
export const JohnUser: User = {
    id: '5',
    uid: '5',
    name: 'John',
    email: 'test-john@tdct.io',
    photo: 'assets/img/default-profile.png',
    displayTimestamp: 'a few hours ago',
    displayLastUpdateTimestamp: 'a few hours ago',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    roles: ['subscriber', 'admin', 'owner'],
    eventCount: 13,
    eventTotal: 6,
    cityTotal: 4,
    venueTotal: 0,
    ticketTotal: 2,
    tableTotal: 3,
    bottleTotal: 2,
    userTotal: 5,
        dob: '',
        gender: '',
    bio: 'John Here. I own a club and want to work with promoters to throw events',
    instagram: 'https://instagram/john',
    twitter: 'https://twitter.com/john',
    snapchat: 'https://snapchat.com/john',
    isPremium: false
};
export const MockUsers: User[] = [JackUser, JaneUser, JimUser, JillUser, JohnUser];
export const DefaultUser: User = {
    id: '',
    uid: '',
    name: '',
    email: '',
    photo: '',
    dob: '',
    gender: '',
    displayTimestamp: '',
    displayLastUpdateTimestamp: '',
    unixLastUpdateTimestamp: 0,
    unixTimestamp: 0,
    promoterProfileCount: 0,
    promoterRequestCount: 0,
    eventCount: 0,
    cityCount: 0,
    venueCount: 0,
    ticketCount: 0,
    tableCount: 0,
    bottleCount: 0,
    deviceCount: 0,
    uploadCount: 0,
    postCount: 0,
    askCount: 0,
    taskCount: 0,
    pingCount: 0,
    messageCount: 0,
    roles: [],
    userPromoterId: '',
    isPremium: false,
};
