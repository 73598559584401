/*
=========================================================================================================
app.component.ts
----------------
Declares the appComponent class and houses its respective functions.
=========================================================================================================
*/
import { Component, NgZone, OnInit } from '@angular/core';
import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ImpThemeService } from './home/imp-theme/imp-theme.service';
import { DarkTheme } from './home/imp-theme/imp-theme.mocks';
import { TdctAuthService } from './home/tdct-auth/tdct-auth.service';
import { TdctUserService } from './home/tdct-user/tdct-user.service';
import { environment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ModalService } from './home/imp-modal/imp-modal.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from './home/tdct-user/tdct-user.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public isLoggedIn: boolean = true;
    envName = environment.name;
    public menuItems: any[] = [];
    userRole: string = '';
    public APP_VERSION: string = environment.version.appVersion;
    public testUser: User = {
        id: 'test-id',
        uid: 'test-id',
        name: 'Test User',
        email: 'test@example.com',
        photo: '',
        displayTimestamp: '',
        displayLastUpdateTimestamp: '',
        unixLastUpdateTimestamp: 0,
        unixTimestamp: 0,
        roles: ['promoter'],
        isPremium: false,
        dob: ''
    };

    constructor(
        public auth: TdctAuthService,
        public theme: ImpThemeService,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private zone: NgZone,
        private deeplinks: Deeplinks,
        private modalService: ModalService,
        private iab: InAppBrowser,
        public afa: AngularFireAuth,
        private menuCtrl: MenuController,
        public user: TdctUserService,
        private aff: AngularFireFunctions,
        private afs: AngularFirestore
    ) {
        this.initializeApp();
        this.setupMenuItems();
        this.fetchUser();
    }

    ngOnInit() {
        // Subscribe to router events to close the menu on route change
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Close the menu after navigation ends
                this.menuCtrl.close();
            }
        });
    }

    toggleTheme() {
        this.theme.toggle();
    }

    async fetchUser() {
        await this.afa.authState.subscribe(async (state) => {
            if (state) {
                try {
                    // Ensure user is fully loaded before setting up menu items
                    const userDoc = await this.afs.doc(`users/${state.uid}`).get().toPromise();
                    const userData = userDoc.data() as User | undefined;

                    if (userData) {
                        // Manually set premium status from Firestore or localStorage
                        const isLocalStoragePremium = localStorage.getItem('testPremiumEnabled') === 'true';
                        const firestorePremium = userData.isPremium ?? false;

                        (this.user.$ as User)!.isPremium = firestorePremium || isLocalStoragePremium;

                        console.log('Fetched User Data:', {
                            firestorePremium: firestorePremium,
                            localStoragePremium: isLocalStoragePremium,
                            finalPremiumStatus: (this.user.$ as User).isPremium
                        });

                        await this.setupMenuItems();
                    } else {
                        console.warn('No user data found in Firestore');
                        // Fallback to local storage premium status
                        const isLocalStoragePremium = localStorage.getItem('testPremiumEnabled') === 'true';
                        (this.user.$ as User)!.isPremium = isLocalStoragePremium;
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    // Fallback to local storage premium status in case of error
                    const isLocalStoragePremium = localStorage.getItem('testPremiumEnabled') === 'true';
                    (this.user.$ as User)!.isPremium = isLocalStoragePremium;
                }
            }
        });
    }

    async setupMenuItems() {
        try {
            console.group('Setup Menu Items Diagnostic');
            console.log('Current User:', JSON.stringify(this.user.$));
            console.log('localStorage testPremiumEnabled:', localStorage.getItem('testPremiumEnabled'));
            
            this.userRole = await this.auth.getUserRole();
            console.log('User Role:', this.userRole);

            switch (this.userRole) {
                case 'promoter':
                    console.log('Setting up promoter menu items');
                    console.log('Detailed Premium Check:', {
                        userServicePremium: (this.user.$ as User).isPremium,
                        localStoragePremium: localStorage.getItem('testPremiumEnabled'),
                        typeOfPremium: typeof (this.user.$ as User).isPremium
                    });

                    this.menuItems = [
                        { title: 'My Events', icon: 'calendar-outline', route: '/user/profile/promoter/events' },
                        { title: 'Create Event', icon: 'add-circle-outline', route: '/promoter/create/event' },
                    ];
                    
                    // Prioritize user service premium status
                    if ((this.user.$ as User).isPremium) {
                        console.log('✅ Adding Analytics Menu Item');
                        this.menuItems.push({ title: 'Analytics', icon: 'analytics-outline', route: '/analytics' });
                    } else {
                        console.log('❌ Adding Upgrade to Premium Menu Item');
                        this.menuItems.push({ title: 'Upgrade to Premium', icon: 'star-outline', route: '/premium/upgrade' });
                    }
                    
                    this.menuItems.push({ title: 'Settings', icon: 'settings-outline', route: '/account' });
                    break;

                default:
                    console.log('Setting up default menu items');
                    this.menuItems = [
                        { title: 'Featured', icon: 'star-outline', route: '/home' },
                        { title: 'Cities', icon: 'map-outline', route: '/events' },
                        { title: 'Orders', icon: 'receipt-outline', route: '/orders' },
                        { title: 'Settings', icon: 'settings-outline', route: '/account' },
                    ];
                    break;
            }

            console.log('Final Menu Items:', this.menuItems);
            console.groupEnd();
        } catch (error) {
            console.error('Error setting up menu items:', error);
            this.userRole = 'user';
            this.menuItems = [
                { title: 'Featured', icon: 'star-outline', route: '/home' },
                { title: 'Cities', icon: 'map-outline', route: '/events' },
                { title: 'Orders', icon: 'receipt-outline', route: '/orders' },
                { title: 'Settings', icon: 'settings-outline', route: '/account' },
            ];
        }
    }

    async togglePremiumStatus() {
        // Toggle the premium status in the user service
        (this.user.$ as User).isPremium = !(this.user.$ as User).isPremium;
        console.log('Premium status:', (this.user.$ as User).isPremium);

        // Manage localStorage for test premium status
        if ((this.user.$ as User).isPremium) {
            localStorage.setItem('testPremiumEnabled', 'true');
            console.log('Setting localStorage testPremiumEnabled to true');
        } else {
            localStorage.removeItem('testPremiumEnabled');
            console.log('Removing localStorage testPremiumEnabled');
        }

        try {
            // Call the test premium membership cloud function
            const callable = this.aff.httpsCallable('testPremiumMembership');
            const result = await callable({ userId: (this.user.$ as User).id }).toPromise();
            console.log('Premium membership test result:', result);

            // Update the menu items and force change detection
            this.zone.run(() => {
                this.setupMenuItems();
            });
        } catch (error) {
            console.error('Error toggling premium status:', error);
        }
    }

    async initiatePremiumMembership() {
        try {
            const callable = this.aff.httpsCallable('initiatePremiumMembership');
            const result = await callable({}).toPromise();
            
            if (result?.url) {
                window.location.href = result.url;
            } else {
                console.error('No URL in response:', result);
                // Optional: Add error handling
            }
        } catch (error: any) {
            console.error('Error initiating premium membership:', error);
            // Optional: Add error handling
        }
    }

    initializeApp() {
        // deeplink listener event
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                const domain = 'imp-events.com';
                const slug = event.url.split(domain).pop();
                if (slug) {
                    this.router.navigateByUrl(slug);
                }
            });
        });

        this.platform.ready().then(() => {
            this.theme.set(DarkTheme);
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    openUrl(url: string) {
        this.iab.create(url, '_system');
    }
}
