import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../imp-event.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { Option } from '../../imp-option/imp-option.model';
import { DefaultOption } from '../../imp-option/imp-option.mocks';
import { AngularFirestore, AngularFirestoreCollectionGroup } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ImpTableService } from '../../imp-table/imp-table.service';
import { PopoverController } from '@ionic/angular';
import { ImpTableOptionOptionComponent } from '../../imp-table/imp-table-option-option/imp-table-option-option.component';
import { ImpThemeService } from '../../imp-theme/imp-theme.service';
import { ImpTableOptionBottleService } from '../../imp-table/imp-table-option-bottle/imp-table-option-bottle.service';
import { ImpTimingService } from '../../imp-timing.service';
import { Promoter } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.model';
import moment from 'moment';

@Component({
    selector: 'app-imp-event-option-table-creator-form',
    templateUrl: './imp-event-option-table-creator-form.component.html',
    styleUrls: ['./imp-event-option-table-creator-form.component.scss'],
})
export class ImpEventTableOptionComponent implements OnInit, OnChanges {

    @Input() option$: Option = DefaultOption;
    tableOptionHidden: boolean = false;
    isFollowingPromoter: boolean = false;
    public justClicked: boolean = true;
    promoterCol: AngularFirestoreCollectionGroup<Promoter>;
    imageUploaded: boolean = false;
    defaultImage: string = 'assets/img/default-profile.png';
    submitted: boolean = false;

    constructor(
        public theme: ImpThemeService,
        public auth: TdctAuthService,
        public user: TdctUserService,
        public promoter: TdctUserPromoterService,
        public event: ImpEventService,
        public table: ImpTableService,
        public option: ImpOptionService,
        public tableOptionBottle: ImpTableOptionBottleService,
        public admin: TdctAdminService,
        public afs: AngularFirestore,
        public aff: AngularFireFunctions,
        public popover: PopoverController,
        public timing: ImpTimingService,
        private cdRef: ChangeDetectorRef,
    ) {
        // Initialize user following promoter check
        this.isUserFollowingEventPromoter();
    }

    ngOnInit() {
        console.log('[ngOnInit] ImpEventTableOptionComponent initialized.');
        this.resetFormValues();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.option$) {
            console.log('[ngOnChanges] Change detected in option data:', changes.option$.currentValue);
            this.markHiddenTableOption();
        }
    }

    toggleTableImageChange() {
        this.auth.uploadingTableOptionImage = !this.auth.uploadingTableOptionImage;
        if (this.option.$.image !== this.defaultImage) {
          this.imageUploaded = true;
        }
      }

    markHiddenTableOption(): void {
        console.log('[markHiddenTableOption] Checking if table option should be hidden.');
        if (this.option.sHidden$.includes(this.option$)) {
            console.log('[markHiddenTableOption] Table option is hidden.');
            this.tableOptionHidden = true;
        } else {
            console.log('[markHiddenTableOption] Table option is visible.');
            this.tableOptionHidden = false;
        }
        this.cdRef.detectChanges();  // Trigger UI change
    }

    async submit(eventOptionTableForm) {
        this.submitted = true;

        if (this.option.$.image !== this.defaultImage && this.validEventOptionTableForm()) {
            this.imageUploaded = true;
            this.auth.loading = true;
            console.log('===========this.option.$.image========', this.option.$.image);
            try {
                await this.createTableOption();
                this.auth.viewingEventOptionTableForm = false;
                this.auth.loading = false;
                await this.popover.dismiss();
                this.resetFormValues();
            } catch (error) {
                error = {
                    code: 'Error',
                    message: 'Error creating event table option'
                };
                this.auth.presentErrorAlert(error);
            }
        }
    }

      validEventOptionTableForm(): boolean {
        return true;
      }

      resetFormValues() {
        this.option.$ = { ...DefaultOption };
        this.submitted = false;
        console.log('Form values have been reset.');
    }
      async createTableOption() {
        console.log('creating table id');
        this.option.$.id = this.afs.createId();
        console.log('table', this.option.$);
        this.stampTime();
        this.stampUserPromoter();
        this.stampEvent();
        this.option.$.settings = ['table'];
        this.option.$.digits = ['event'];
        if (this.option.$.ticketsAvailable === undefined) {
          this.option.$.ticketsAvailable = 0;
        }

        if (this.option.$.tablesAvailable === undefined) {
          this.option.$.tablesAvailable = 1;
        }
        console.log('creating table option under event');
        const eventTableOptionPath = `events/${this.event.$.id}/options/${this.option.$.id}`;
        console.log('creating table in Tables');
        await this.afs.doc(eventTableOptionPath).set(this.option.$);
      }

      stampTime() {
        this.option.$.displayTimestamp = moment().format('YYYY-MM-DD');
        this.option.$.displayLastUpdateTimestamp = this.option.$.displayTimestamp;
        this.option.$.unixTimestamp = moment().unix();
        this.option.$.unixLastUpdateTimestamp = this.option.$.unixTimestamp;
      }

      stampUserPromoter() {
        this.option.$.userId = this.user.$.id;
        this.option.$.userEmail = this.user.$.email;
        this.option.$.userName = this.user.$.name;
        this.option.$.userPhoto = this.user.$.photo;
        this.option.$.promoterId = this.event.$.promoterId;
        this.option.$.promoterEmail = this.event.$.promoterEmail;
        this.option.$.promoterName = this.event.$.promoterName;
        this.option.$.promoterPhoto = this.event.$.promoterPhoto;
        this.option.$.promoterBio = this.event.$.promoterBio;
      }

      stampEvent() {
        this.option.$.eventId = this.event.$.id;
        this.option.$.eventName = this.event.$.name;
        this.option.$.eventImage = this.event.$.image;
        this.option.$.eventDescription = this.event.$.description;
      }

    async toggleTableOptionHide() {
        console.log('[toggleTableOptionHide] Toggling table option visibility.');
        try {
            if (this.auth.viewingEventStripeElementsForm) {
                console.log('[toggleTableOptionHide] Hiding Stripe Elements Form.');
                this.auth.viewingEventStripeElementsForm = false;
            } else {
                if (!this.option.sHidden$.includes(this.option$)) {
                    console.log('[toggleTableOptionHide] Hiding the table option.');
                    this.option.sHidden$.push(this.option$);
                } else {
                    console.log('[toggleTableOptionHide] Showing the table option.');
                    this.option.sHidden$ = this.option.sHidden$.filter(event => event !== this.option$);
                }
                this.markHiddenTableOption();
                if (!this.tableOptionHidden) {
                    console.log('[toggleTableOptionHide] Reloading table option because it is now visible.');
                    await this.reloadTableOption();
                }
            }
        } catch (error) {
            console.error('[toggleTableOptionHide] Error toggling table option visibility:', error);
        }
    }

    async reloadTableOption() {
        console.log('[reloadTableOption] Reloading table option.');
        this.option.loading = true;
        try {
            this.option$ = await this.option.get(this.option$.id);
            console.log('[reloadTableOption] Successfully reloaded table option:', this.option$);
            this.option$ = this.auth.momentLastUpdatedFromNow(this.option$);
        } catch (error) {
            console.error('[reloadTableOption] Error reloading table option:', error);
        } finally {
            this.option.loading = false;
            this.cdRef.detectChanges();  // Trigger UI change
        }
    }

    toggleEventStripeElementsForm(): void {
        console.log('[toggleEventStripeElementsForm] Toggling Stripe Elements Form visibility.');
        this.justClicked = true;
        this.auth.viewingEventStripeElementsForm = !this.auth.viewingEventStripeElementsForm;
        if (this.auth.viewingEventStripeElementsForm) {
            console.log('[toggleEventStripeElementsForm] Stripe Elements Form is now visible.');
            this.option.set(this.option$);
            this.tableOptionBottle.load();
        } else {
            console.log('[toggleEventStripeElementsForm] Stripe Elements Form is now hidden.');
        }
    }

    toggleTableOptionManager(): void {
        console.log('[toggleTableOptionManager] Toggling Table Option Manager visibility.');
        this.auth.viewingEventTableOptionPopover = !this.auth.viewingEventTableOptionPopover;
        if (this.auth.viewingEventTableOptionPopover) {
            console.log('[toggleTableOptionManager] Table Option Manager is now visible.');
            this.option.set(this.option$);
            this.presentPopover();
        } else {
            console.log('[toggleTableOptionManager] Table Option Manager is now hidden.');
        }
    }

    async presentPopover() {
        console.log('[presentPopover] Presenting table option popover.');
        try {
            this.auth.optionControl = await this.popover.create({
                component: ImpTableOptionOptionComponent,
                showBackdrop: false,
                animated: true
            });

            this.auth.optionControl.onDidDismiss().then(() => {
                console.log('[presentPopover] Popover dismissed.');
                this.auth.viewingEventTableOptionPopover = false;
            });

            return await this.auth.optionControl.present();
        } catch (error) {
            console.error('[presentPopover] Error presenting popover:', error);
        }
    }

    routeLogin() {
        console.log('[routeLogin] Routing to login page.');
        this.auth.signOut();
        this.theme.tab.selected = 'moreTab';
        this.auth.viewing = true;
        this.auth.toggleLoginForm();
    }

    async isUserFollowingEventPromoter() {
        this.isFollowingPromoter = false;
        console.log('[isUserFollowingEventPromoter] Checking if the user is following the event promoter.');
        console.log('[isUserFollowingEventPromoter] User ID:', this.user.$.id);

        if (this.user.$.id === '') {
            console.log('[isUserFollowingEventPromoter] User ID is empty, not following.');
            this.isFollowingPromoter = false;
            this.justClicked = false;
            return;
        }

        try {
            // Execute the query to get a QuerySnapshot
            const promoterSnapshot = await this.afs.collectionGroup<Promoter>('promoters', ref =>
                ref.where('id', '==', this.event.$.promoterId).orderBy('displayTimestamp', 'asc')
            ).get().toPromise();

            // Iterate over the documents in the snapshot
            promoterSnapshot.forEach((doc) => {
                console.log('[isUserFollowingEventPromoter] Found promoter document:', doc.id, doc.data());
                const data = doc.data();
                if (data.followers.includes(this.user.$.id) || data.userId === this.user.$.id) {
                    console.log('[isUserFollowingEventPromoter] User is a follower of or is the promoter.');
                    this.isFollowingPromoter = true;
                }
            });
        } catch (error) {
            console.error('[isUserFollowingEventPromoter] Error checking promoter followers:', error);
        }

        this.justClicked = false;
    }
}
