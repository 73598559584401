import { Injectable } from '@angular/core';
import { Table } from './imp-table.model';
import { DefaultTable, MockTables } from './imp-table.mocks';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import * as moment from 'moment';
import { AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { first } from 'rxjs/operators';
import { ImpOptionService } from '../imp-option/imp-option.service';
import { ImpEventService } from '../imp-event/imp-event.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class ImpTableService {

  $: Table = DefaultTable;
  s$: Table[] = MockTables;
  all$: Table[] = [];
  sHidden$: Table[] = [];
  search: string = '';
  count: number = 0;
  total: number = 0;
  viewing: boolean = false;
  loading: boolean = false;
  noneFound: boolean = false;
  editing: boolean = false;
  managing: boolean = false;
  sharing: boolean = false;
  docIdTemp: string = '';
  doc: AngularFirestoreDocument<Table>;
  col: AngularFirestoreCollection<Table>;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public option: ImpOptionService,
    public event: ImpEventService,
    public afs: AngularFirestore
  ) { }

  async get(id: string): Promise<Table> {
    const tablePath = 'tables/' + id;
    this.doc = this.afs.doc<Table>(tablePath);
    return await this.doc.valueChanges().pipe(first()).toPromise();
  }

  set(table: Table): void {
    this.$ = table;
    this.viewing = true;
    this.$ = this.auth.momentCreatedExactly(this.$);
    this.$ = this.auth.momentLastUpdatedFromNow(this.$);
  }

  async load(): Promise<Table[]> {
    this.loading = true;
    this.count = 0;
    this.total = 0;
    this.s$ = [];
    this.all$ = [];
    this.loading = true;
    await this.filter();
    this.s$ = await this.col.valueChanges().pipe(first()).toPromise();
    this.s$ = this.s$.map(table => this.auth.momentCreatedFromNow(table));
    this.s$ = this.s$.map(table => this.auth.momentLastUpdatedFromNow(table));
    this.set(this.s$[0]);
    this.slowCount();
    this.loading = false;
    return this.s$;
  }

  async filter() {
    if (this.auth.viewingUserTables) {
      this.col = await this.afs.collection<Table>('tables', ref => ref
        .where('userId', '==', this.user.$.id)
        .orderBy('unixLastUpdateTimestamp', 'desc'));
    } else {
      this.col = await this.afs.collection<Table>('tables', ref => ref.orderBy('unixLastUpdateTimestamp', 'desc'));
    }
  }

  slowCount() {
    this.all$ = this.s$;
    this.total = this.s$.length;
    if (this.s$.length === 1) {
      this.count = 1;
    } else {
      this.s$ = this.all$.splice(0, 3);
      const interval = setInterval(() => {
        this.count++;
        if (this.count >= this.total) {
          clearInterval(interval);
          this.count = this.total;
        }
      }, 100);
    }
  }

  async getTablesByUserId(userId: string): Promise<Table[]> {
    this.loading = true;
    this.noneFound = false;
    this.s$ = [];

    const userTablesCollection = this.afs.collection<Table>('tables', ref =>
      ref.where('userId', '==', userId)
        .orderBy('unixLastUpdateTimestamp', 'desc')
    );

    this.s$ = await userTablesCollection.valueChanges().pipe(first()).toPromise();

    if (this.s$.length === 0) {
      this.noneFound = true;
    }

    this.loading = false;
    return this.s$;
}

  showMore() {
    let loadinTables: Table[] = [];
    if (this.all$.length === 1) {
      loadinTables = this.all$.splice(0, 1);
    } else if (this.all$.length === 2) {
      loadinTables = this.all$.splice(0, 2);
    } else { loadinTables = this.all$.splice(0, 3); }
    this.s$.push(...loadinTables);
  }

  sanitize(): void {
    this.s$ = [];
  }

  clear(): void {
    this.editing = false;
  }

  toggleView(): void {
    this.clear();
    this.viewing = !this.viewing;
    if (this.viewing) {
      this.loading = true;
      this.set(this.$);
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  toggleEdit(): void {
    this.editing = !this.editing;
  }


 async create(source: any) {
   // Correctly assign the purchase ID here
   // this.$.id = source.id; // <-- Use the purchase ID directly
   this.$.id = this.afs.createId() || '1337';

   console.log('[imp-table.service]create createGenerated ID:', this.$.id);

   this.$.optionId = this.option.$.id;
   this.$.stripeCardToken = source.id;
   this.$.name = `${this.option.$.name}`;
   this.$.description = `1 Table at ${this.event.$.name}`;
   this.$.image = this.option.$.image;
   this.$.link = `https://imp-events.com/table/${this.$.id}`;
   this.$.route = `table/${this.$.id}`;
   this.$.price = this.option.$.price;
   this.$.temporary = true;

   // Decrement the number of tables available locally
   if (this.option.$.tablesAvailable !== undefined) {
     this.option.$.tablesAvailable -= 1;
   }

   this.stampUser();
   this.stampPromoter();
   this.stampTime();
   this.stampEventOption();

   await this.createTableDoc();
 }


  async createFromCheckout(additionalBottleCount: number) {
    console.log('[imp-table.service].createfromCheckout session: ');
    this.docIdTemp = this.afs.createId();
    // this.$.id = this.afs.createId() || '1337';
    this.$.id = this.docIdTemp;
    console.log('[imp-table.service]create createGenerated ID:', this.$.id); // Debugging ID
    console.log('[afs.createId()]', this.afs.createId());
    this.$.price = this.option.$.price;
    this.$.name = `${this.option.$.name}`;
    this.$.optionId = this.option.$.id;
    this.$.description = `1 Table at ${this.event.$.name}`;
    this.$.image = this.option.$.image;
    this.$.bottlesIncluded = this.option.$.bottlesIncluded || 0;
    this.$.bottlesPurchased = additionalBottleCount || 0;
    // decrement tables from local event table option variable
    if (this.option.$.tablesAvailable !== undefined) {
      this.option.$.tablesAvailable -= 1;
    }
    this.$.link = `https://imp-events.com/table/${this.$.id}`;
    this.$.route = `table/${this.$.id}`;
    this.stampUser();
    this.stampPromoter();
    this.stampTime();
    this.stampEventOption();
    console.log('creating table');
    console.log('table', JSON.stringify(this.$));
    await this.createTableDoc();
    return JSON.stringify(this.$);
  }
async createTableDoc() {
    try {
        console.log('Attempting to create table document...');

        // Ensure the required fields are valid
        if (!this.$ || typeof this.$ !== 'object') {
            console.error('Invalid table data provided:', this.$);
            throw new Error('Table data is invalid or undefined.');
        }

        // Validate or generate a document ID
//         const docId = this.$.id && typeof this.$.id === 'string' && this.$.id.trim() !== ''
//             ? this.$.id
//             : this.afs.createId(); // Auto-generate an ID if not provided
        const docId = this.docIdTemp;

        // Construct document path
        const docPath = `tables/${docId}`;
        console.log('Document Path:', docPath);

        // Prepare table data
        const tableData = {
         ...this.$, // Include any additional fields
            name: this.$.name || 'Untitled Table', // Default name if not provided
            eventId: this.$.eventId || null, // Associated event ID
            id: docId, // Firestore document ID
            docIdHere: this.docIdTemp, // Firestore document ID
        };

        // Save to Firestore
        await this.afs.doc(docPath).set(tableData);
        console.log('Document successfully created at:', docPath);
    } catch (error) {
        console.error('Error creating table document:', error);
        throw error;
    }
}

  stampTime() {
    this.$.displayTimestamp = moment().format('YYYY-MM-DD');
    this.$.displayLastUpdateTimestamp = this.$.displayTimestamp;
    this.$.unixTimestamp = moment().unix();
    this.$.unixLastUpdateTimestamp = this.$.unixTimestamp;
  }

stampUser() {
    this.$.userId = this.user.$?.id || 'unknown-user-id';
    this.$.userEmail = this.user.$?.email || 'unknown-email@example.com';
    this.$.userName = this.user.$?.name || 'Anonymous';
    this.$.userPhoto = this.user.$?.photo || 'default-photo-url';
}


stampPromoter() {
    this.$.promoterId = this.option.$?.promoterId || 'unknown-promoter-id';
    this.$.promoterEmail = this.option.$?.promoterEmail || 'unknown-email@example.com';
    this.$.promoterName = this.option.$?.promoterName || 'Unknown Promoter';
    this.$.promoterPhoto = this.option.$?.promoterPhoto || 'default-photo-url';
    this.$.promoterBio = this.option.$?.promoterBio || 'No bio available';
}

stampEventOption() {
    this.$.eventId = this.event.$?.id || 'unknown-event-id';
    this.$.eventName = this.event.$?.name || 'Unnamed Event';
    this.$.eventImage = this.event.$?.image || 'default-event-image-url';
    this.$.eventDescription = this.event.$?.description || 'No description available';
    this.$.displayStartTimestamp = this.event.$?.displayStartTimestamp || 'N/A';
    this.$.displayEndTimestamp = this.event.$?.displayEndTimestamp || 'N/A';
    this.$.unixStartTimestamp = this.event.$?.unixStartTimestamp || 0;
    this.$.unixEndTimestamp = this.event.$?.unixEndTimestamp || 0;
    this.$.street = this.event.$?.street || 'Unknown Street';
    this.$.city = this.event.$?.city || 'Unknown City';
    this.$.state = this.event.$?.state || 'Unknown State';
    this.$.zipcode = this.event.$?.zipcode || '00000';
    this.$.country = this.event.$?.country || 'Unknown Country';
}

}
