import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../../environments/environment';
import { ImpTicketService } from '../imp-ticket/imp-ticket.service';
import { ImpEventService } from '../imp-event/imp-event.service';
import { ImpEventStripeElementsFormComponent } from '../imp-event/imp-event-stripe-elements-form/imp-event-stripe-elements-form.component';
import { ImpStripeCheckoutComponent } from '../imp-stripe-checkout/imp-stripe-checkout.component';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { CheckoutReturnService } from './checkout-return-page.service';
import { Ticket } from '../imp-ticket/imp-ticket.model';
declare var Stripe;

@Component({
  selector: 'app-checkout-return-page',
  templateUrl: './checkout-return-page.component.html',
  styleUrls: ['./checkout-return-page.component.scss'],
})
export class CheckoutReturnPageComponent implements OnInit {
  sessionId: string = this.order.stripeSessionId;
  success: boolean = this.order.success;
  sessionObj: object = this.order.sessionObj;
  ticketObj: any = {...this.order.ticketObj};
  tableObj: any = {...this.order.tableObj};
  isTicket: boolean = this.order.isTicket;
  isFree: boolean = this.order.isFree;
  isSubscription: boolean = this.order.isSubscription;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    private route: ActivatedRoute,
    private afFun: AngularFireFunctions,
    public ticket: ImpTicketService,
    public order: CheckoutReturnService,
    private router: Router
  ) { }

  ngOnInit() {
    this.auth.loading = true;
    this.sessionObj = this.order.sessionObj;
    this.sessionId = this.order.stripeSessionId;
    this.success = this.order.success;
    if (this.sessionObj && (this.sessionObj as any).mode === 'subscription') {
      this.isSubscription = true;
    }
    if (this.isFree){
      this.order.setReturnPageLoaded(true);
    }
    if (this.sessionObj){
      this.order.setReturnPageLoaded(true);
    }
    console.log('tableobj.event: ', this.tableObj.eventImage);
  }



  routeToEvent() {
    if (this.isTicket && this.ticketObj?.eventId) {
      this.router.navigate(['/event', this.ticketObj.eventId]);
    } else if (!this.isTicket && this.tableObj?.eventId) {
      this.router.navigate(['/event', this.tableObj.eventId]);
    } else {
      this.router.navigate(['/events']);
    }
  }

  routeToAccount() {
    this.router.navigate(['/account']);
  }

}
