import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-investor-celebration',
  template: `
    <ion-content class="celebration-modal">
      <div class="celebration-container">
        <div class="champagne-animation">
          <lottie-player
            src="https://lottie.host/f91ea547-9eca-4308-ba60-5913a2fc36a5/xij083vKw9.lottie"
            background="transparent"
            speed="1"
            style="width: 300px; height: 300px;"
            autoplay
            (complete)="animationComplete()"
          ></lottie-player>
        </div>
        <h1>Cheers to New Beginnings!</h1>
        <p>Join us in celebrating this exciting investment opportunity.</p>
        <ion-button expand="block" (click)="goToInvestorPage()">
          Learn More
          <ion-icon name="arrow-forward" slot="end"></ion-icon>
        </ion-button>
      </div>
    </ion-content>
  `,
  styles: [`
    .celebration-modal {
      --background: rgba(0, 0, 0, 0.9);
    }
    .celebration-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 20px;
    }
    .champagne-animation {
      margin-bottom: 20px;
    }
    h1 {
      color: white;
      margin-bottom: 10px;
    }
    p {
      color: #ccc;
      margin-bottom: 20px;
    }
    ion-button {
      --background: var(--ion-color-success);
    }
  `]
})
export class InvestorCelebrationComponent {
  constructor(private modalCtrl: ModalController) {}

  animationComplete() {
    // Optional: Add any actions after animation completes
  }

  goToInvestorPage() {
    window.open('https://imp-app.com', '_blank');
    this.modalCtrl.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
