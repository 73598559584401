export default {
    production : {
        appVersion: '2.1.2 (50)',
        production: true,
    },
    dev: {
        appVersion: '2.1.2 (50)',
        production: false,
    },
    uat: {
        appVersion: '2.1.2 (50)',
        production: false,
    }
};
