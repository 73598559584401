import { Injectable } from '@angular/core';
import { User } from './tdct-user.model';
import { DefaultUser, MockUsers } from './tdct-user.mocks';
import * as moment from 'moment';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { take, first } from 'rxjs/operators';
import { Auth } from '../tdct-auth/tdct-auth.model';
import { Promoter } from '../../../../functions/src/tdct-user-promoter/tdct-user-promoter.model';

@Injectable({
  providedIn: 'root'
})
export class TdctUserService {

  $: User = DefaultUser;
  s$: User[] = MockUsers;
  all$: User[] = [];
  search: string = '';
  count: number = 0;
  total: number = 0;
  viewing: boolean = false;
  loading: boolean = false;
  noneFound: boolean = false;
  editing: boolean = false;
  doc: AngularFirestoreDocument<User>;
  col: AngularFirestoreCollection<User>;
  roles: any[];
  uid: string | number | boolean;
  email: string | number | boolean;
  isPremium: boolean;

  constructor(
    public afs: AngularFirestore,
    private aff: AngularFireFunctions,
  ) { }

  async get(id: string): Promise<User> {
    const userPath = 'users/' + id;
    this.doc = this.afs.doc<User>(userPath);
    return await this.doc.valueChanges().pipe(take(1)).toPromise();
  }

  async set(user: User) {
    this.$ = user;
    this.viewing = true;
    if (this.$.id) {
      this.$ = this.momentLastUpdatedFromNow(this.$);
      this.$ = this.momentCreatedExactly(this.$);
    }
    
    // Preserve test premium status if it was previously set to true
    if (localStorage.getItem('testPremiumEnabled') === 'true') {
      this.$.isPremium = true;
    } else {
      // Ensure isPremium is set, defaulting to false if not present
      this.$.isPremium = this.$.isPremium || false;
    }
    
    await this.getPromoterArray();
  }

  async getPromoterArray() {
    // console.log('getting promoter array');
    const promoterCol = await this.afs.collection<Promoter>(`users/${this.$.id}/promoters`);
    // console.log(promoterCol);
    const promoterArray = await promoterCol.valueChanges().pipe(first()).toPromise();
    if (promoterArray.length > 0) {
      // console.log(promoterArray[0].id);
      this.$.userPromoterId = promoterArray[0].id;
      // console.log('this.user.$.userpromoterId: ' + this.$.userPromoterId);
    }
    return promoterArray;
  }


  async load(): Promise<User[]> {
    this.loading = true;
    this.count = 0;
    this.total = 0;
    this.s$ = [];
    this.all$ = [];
    this.loading = true;
    this.col = await this.afs.collection<User>('users', ref => ref.orderBy('unixLastUpdateTimestamp', 'desc'));
    this.s$ = await this.col.valueChanges().pipe(first()).toPromise();
    if (this.s$.length > 0) {
      this.s$ = this.s$.map(user => this.momentCreatedExactly(user));
      this.s$ = this.s$.map(user => this.momentLastUpdatedFromNow(user));
      await this.set(this.s$[0]);
      this.slowCount();
    }
    this.loading = false;
    return this.s$;
  }

  momentCreatedExactly(digit: any): any {
    digit.displayTimestamp = moment.unix(digit.unixTimestamp).format('dddd[,] MMMM Do[,] YYYY [at] h:mm a');
    return digit;
  }

  momentLastUpdatedFromNow(digit: any): any {
    digit.displayLastUpdateTimestamp = moment.unix(digit.unixLastUpdateTimestamp).fromNow();
    return digit;
  }

  slowCount() {
    this.all$ = this.s$;
    this.total = this.s$.length;
    if (this.s$.length === 1) {
      this.count = 1;
    } else {
      this.s$ = this.all$.splice(0, 3);
      const interval = setInterval(() => {
        this.count++;
        if (this.count >= this.total) {
          clearInterval(interval);
          this.count = this.total;
        }
      }, 100);
    }
  }

  showMore() {
    let loadinUsers: User[] = [];
    if (this.all$.length === 1) {
      loadinUsers = this.all$.splice(0, 1);
    } else if (this.all$.length === 2) {
      loadinUsers = this.all$.splice(0, 2);
    } else { loadinUsers = this.all$.splice(0, 3); }
    this.s$.push(...loadinUsers);
  }

  sanitize(): void {
    this.s$ = [];
  }

  clear(): void {
    this.editing = false;
  }

  toggleEdit(): void {
    this.editing = !this.editing;
  }

  async create(auth: Auth): Promise<User> {
    const user: User = DefaultUser;
    user.id = auth.uid;
    user.email = auth.email;
    user.name = auth.displayName;
    user.photo = auth.photoURL;
    user.displayTimestamp = moment().format('YYYY-MM-DD');
    user.displayLastUpdateTimestamp = moment().format('YYYY-MM-DD');
    user.unixLastUpdateTimestamp = moment().unix();
    user.unixTimestamp = moment().unix();
    user.roles = ['subscriber'];
    console.log('user', JSON.stringify(user));
    await this.get(user.id);
    await this.doc.set(user);
    return user;
  }

  /**
   * Update the user's photo URL in Firestore
   * @param userId - The ID of the user
   * @param photoUrl - The new photo URL
   */
  async updateUserPhoto(userId: string, photoUrl: string): Promise<void> {
    try {
      const userDoc = this.afs.doc<User>(`users/${userId}`);
      await userDoc.update({ photo: photoUrl }); // Update the photo field in the document
      console.log('User photo updated in Firestore');
    } catch (error) {
      console.error('Error updating user photo in Firestore:', error);
    }
  }

  async createPremiumSubscription(): Promise<any> {
    const createPremiumSubscription = this.aff.httpsCallable("createPremiumSubscription");
    return await createPremiumSubscription({}).toPromise();
  }

  async managePremiumSubscription(): Promise<any> {
    const managePremiumSubscription = this.aff.httpsCallable('managePremiumSubscription');
    return await managePremiumSubscription({}).toPromise();
  }

  async getPremiumSubscriptionDetails(): Promise<any> {
    const getPremiumDetails = this.aff.httpsCallable('getPremiumSubscriptionDetails');
    return await getPremiumDetails({}).toPromise();
  }

  async deleteUser(): Promise<any> {
    const deleteUser = this.aff.httpsCallable('deleteUser');
    return await deleteUser({}).toPromise();
  }

}
