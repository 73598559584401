import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../../imp-event/imp-event.service';
import { ImpTableService } from '../imp-table.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { Option } from '../../imp-option/imp-option.model';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'app-imp-table-option-editor-form',
  templateUrl: './imp-table-option-editor-form.component.html',
  styleUrls: ['./imp-table-option-editor-form.component.scss'],
})
export class ImpTableOptionEditorFormComponent implements OnInit {

  submitted: boolean = false;
  originalTableImage: string = this.option.$.image;
  uploadNewTableImage: boolean = false;
  constructor(
    public auth: TdctAuthService,
    public promotor: TdctUserPromoterService,
    public event: ImpEventService,
    public table: ImpTableService,
    public option: ImpOptionService,
    public admin: TdctAdminService,
    public afs: AngularFirestore,
    public alertCtrl: AlertController,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.option.editing = false;
  }

  toggleTableOptionImageChange(): void {
    this.auth.uploadingTableOptionImage = !this.auth.uploadingTableOptionImage;
  }
  editTableImage(): void {
    this.uploadNewTableImage = true;
  }

  cancelEditTableImage(): void {
    this.uploadNewTableImage = false;
  }

  async submit(tableOptionForm) {
    this.submitted = true;
    this.auth.loading = true;
    this.stamp(tableOptionForm);
    await this.editOption();
    this.auth.viewingTableOptionEditorForm = false;
    this.auth.loading = false;
  }

  stamp(tableOptionForm: Option) {
    this.option.$.price = tableOptionForm.price || this.option.$.price;
    this.option.$.name = tableOptionForm.name || this.option.$.name;
    this.option.$.tablesAvailable = tableOptionForm.tablesAvailable || this.option.$.tablesAvailable;
    this.option.$.description = this.option.$.description.replace(/(\r\n\t|\n|\r\t)/gm, '');
    console.log('===========this.option.$.image========', this.option.$.image);
    console.log('===========origianal image========', this.originalTableImage);

  }

  async editOption() {
    try {
      if (this.uploadNewTableImage){
        await this.afs.doc(`events/${this.option.$.eventId}/options/${this.option.$.id}`).update({
          price: this.option.$.price,
          name: this.option.$.name,
          description: this.option.$.description,
          image: this.option.$.image,
          unixLastUpdateTimestamp: moment().unix()
        });
      } else {
        await this.afs.doc(`events/${this.option.$.eventId}/options/${this.option.$.id}`).update({
          price: this.option.$.price,
          name: this.option.$.name,
          description: this.option.$.description,
          unixLastUpdateTimestamp: moment().unix()
        });
      }
    } catch (error) {
      await this.auth.presentErrorAlert(error);
    }
  }

  async deleteTable(tableOption) {
    this.table.loading = true;
    console.log(tableOption.id);
    await this.event.afs.doc('events/' + this.event.$.id + '/options/' + tableOption.id).delete();
    --this.admin.digitalPoints;
    --this.admin.brandDigitalPoints;
    --this.admin.$.tableCount;
    this.admin.dismissOptionControl();
    const alert = await this.alertCtrl.create({
      header: 'Table Deleted',
      message: 'This table has been deleted.',
      buttons: ['OK']
    });
    await alert.present();
    // delete 1 from event table option count
    --this.event.$.tableOptionCount;
    this.auth.viewingTableOptionEditorForm = false;
    this.auth.loading = false;
    // close table accordion
    this.toggleEventTableOptions();
  }

  toggleEventTableOptions(): void {
    console.log('toggleEventTableOptions');
    if (this.auth.viewingEventTableOptionPopover) { this.auth.viewingEventTableOptionPopover = false; }
    this.auth.viewingEventTableOptions = !this.auth.viewingEventTableOptions;
    if (!this.auth.viewingEventTableOptions) {
      this.auth.viewingEventOptionTableForm = false;
      this.auth.viewingTableOptionEditorForm = false;
    }
  }

}
