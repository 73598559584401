// src/app/services/IMPExternalAnalyticsManager.service.ts
import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { TdctUserService } from '../home/tdct-user/tdct-user.service';
import { User } from '../home/tdct-user/tdct-user.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../environments/environment';
import { 
    AnalyticsEventParams, 
    AnalyticsUserProperties, 
    AnalyticsValidationConfig,
    DebugConfig 
} from './analytics.types';

@Injectable({
    providedIn: 'root',
})
export class IMPExternalAnalyticsManagerService {
    // Standard Google Analytics Events
    readonly GA_SIGN_UP = 'sign_up';           // Recommended GA event
    readonly GA_LOGIN = 'login';               // Recommended GA event
    readonly GA_SHARE = 'share';               // Recommended GA event

    // Custom Auth Flow Events
    readonly AUTH_SESSION_STARTED = 'session_started';
    readonly AUTH_SESSION_ENDED = 'session_ended';
    readonly AUTH_LOGIN_ERROR = 'login_error';
    readonly AUTH_LOGOUT = 'logout';              // Custom logout event
    readonly AUTH_PASSWORD_RESET_REQUESTED = 'password_reset_requested';
    readonly AUTH_PASSWORD_RESET_EMAIL_SENT = 'password_reset_email_sent';
    readonly AUTH_PASSWORD_RESET_ERROR = 'password_reset_error';

    private readonly validationConfig: AnalyticsValidationConfig = {
        maxEventNameLength: 40,
        maxParamNameLength: 40,
        maxParamValueLength: 100,
        maxParamsPerEvent: 25,
        maxEventsPerApp: 500
    };

    private readonly debugConfig: DebugConfig = {
        enabled: true,
        sessionTimeoutDuration: 1800000, // 30 minutes
        debugViewEnabled: true,
        logLevel: 'debug'
    };

    constructor(
        private analytics: AngularFireAnalytics,
        private userService: TdctUserService,
        private afa: AngularFireAuth
    ) {
        this.initializeAnalytics();
    }

    private async initializeAnalytics() {
        console.log('Initializing analytics with config:', {
            debugEnabled: this.debugConfig.enabled,
            environment: environment.production ? 'production' : 'development',
            measurementId: environment.firebase.measurementId
        });
        
        if (this.debugConfig.enabled) {
            await this.enableDebugMode();
        }
    }

    private async enableDebugMode() {
        try {
            console.log('Enabling analytics debug mode');
            // Enable debug mode in Google Analytics
            window['ga-disable-' + environment.firebase.measurementId] = false;
            // @ts-ignore
            window.ga_debug = { trace: true };
        } catch (error) {
            console.error('Error enabling debug mode:', error);
        }
    }

    private validateEventName(eventName: string): string {
        if (!eventName || typeof eventName !== 'string' || eventName.trim() === '') {
            console.error('Invalid event name:', eventName);
            throw new Error('Event name is required and must be a non-empty string');
        }
        return eventName.trim().substring(0, this.validationConfig.maxEventNameLength);
    }

    private validateEventParams(params: AnalyticsEventParams): AnalyticsEventParams {
        const validParams: AnalyticsEventParams = {};
        
        Object.entries(params).forEach(([key, value]) => {
            if (Object.keys(validParams).length >= this.validationConfig.maxParamsPerEvent) {
                return;
            }

            const validKey = key.substring(0, this.validationConfig.maxParamNameLength);
            let validValue = value;

            if (typeof value === 'string') {
                validValue = value.substring(0, this.validationConfig.maxParamValueLength);
            }

            validParams[validKey] = validValue;
        });

        return validParams;
    }

    async logEvent(eventName: string, eventData: AnalyticsEventParams = {}): Promise<void> {
        try {
            if (!eventName) {
                console.error('Attempted to log event with empty name');
                return;
            }
            console.log('Logging event:', eventName, eventData);
            const validEventName = this.validateEventName(eventName);
            const validEventData = this.validateEventParams(eventData);
            await this.analytics.logEvent(validEventName, validEventData);
            console.log('Event logged successfully:', eventName, validEventData);
        } catch (error) {
            console.error('Error logging event:', error);
        }
    }

    async logEventWithUserContext(eventName: string, eventData: AnalyticsEventParams = {}): Promise<void> {
        try {
            if (!eventName) {
                console.error('Attempted to log event with user context but event name is empty');
                return;
            }
            console.log('Logging event with context:', { eventName, eventData });
            const firebaseUser = await this.afa.currentUser;
            let userData = null;
            
            if (firebaseUser) {
                userData = await this.userService.get(firebaseUser.uid);
            }

            const enhancedEventData: AnalyticsEventParams = {
                ...eventData,
                user_id: userData?.id || 'anonymous',
                user_roles: userData?.roles?.join(',') || 'none',
                user_email: userData?.email || 'anonymous',
                user_name: userData?.name || 'anonymous',
                client_timestamp_ms: Date.now().toString(),
                environment: environment.production ? 'production' : 'development'
            };

            const validEventData = this.validateEventParams(enhancedEventData);
            const validEventName = this.validateEventName(eventName);
            await this.analytics.logEvent(validEventName, validEventData);
            console.log('Event with context logged successfully:', { eventName, validEventData });
        } catch (error) {
            console.error('Error logging event with context:', error, {
                eventName,
                eventData
            });
        }
    }

    async setUserContext(user: User): Promise<void> {
        if (!user) return;

        try {
            const properties: AnalyticsUserProperties = {
                user_id: user.id,
                user_roles: user.roles?.join(',') || 'none',
                user_email: user.email || 'anonymous',
                user_name: user.name || 'anonymous',
                account_created: new Date(user.unixTimestamp).toISOString(),
                environment: environment.production ? 'production' : 'development'
            };

            const validatedProperties = this.validateEventParams(properties);
            await this.analytics.setUserId(user.id);
            await this.analytics.setUserProperties(validatedProperties);
            console.log('User context set in analytics:', validatedProperties);
        } catch (error) {
            console.error('Error setting user context:', error);
        }
    }
}
